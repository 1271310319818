
/* Pour le titre */
.title {
    font-family: 'Cinzel', serif;
    font-weight: 700;
    font-size: 2rem; /* ou ajuste selon la taille souhaitée */
    text-align: center;
    color: #333; /* une couleur douce pour le texte */
  }
  
  /* Pour le texte en italique */
  .text {
    font-family: 'Raleway', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.8;
    text-align: left;
    color: #555; /* légèrement gris pour une lecture fluide */
  }
  
  @media (max-width: 768px) {
    .col-md-3 img {
      max-width: 80%; /* Réduire la taille des images sur mobile */
      margin: 0 auto; /* Centrer les images */
    }
  }
  
#Apropos{
  background-color: whitesmoke;
}