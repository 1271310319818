/* Styles de base */
.hero {
  width: 100%;
  height: 100vh; /* Utilisez toute la hauteur de la vue */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(2, 2, 2, 0.5)),
              url('../../../public/Back.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px 0;
}

.hero h2 {
  font-size: 3rem; /* Taille de police relative */
  color: #ffffff;
  margin-bottom: 1rem;
}

.hero p {
  color: #ffffff;
  margin: 5px 0 0 0;
  font-size: 1.5rem;
}

.hero .btn {
  font-size: 0.9rem;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.hero .btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Styles pour les petits écrans (téléphones) */
@media (max-width: 768px) {
  .hero h2 {
    font-size: 2rem; /* Taille de police réduite */
  }

  .hero p {
    font-size: 1rem; /* Taille de police réduite */
  }

  .hero .btn {
    font-size: 1rem; /* Taille de police réduite */
    padding: 8px 16px; /* Padding réduit */
  }

  .additional-buttons .btn {
    font-size: 0.9rem; /* Taille de police réduite pour les boutons supplémentaires */
    padding: 6px 12px; /* Padding réduit */
  }
}

/* Styles pour les écrans moyens (tablettes) */
@media (min-width: 768px) and (max-width: 992px) {
  .hero h2 {
    font-size: 2.5rem; /* Taille de police intermédiaire */
  }

  .hero p {
    font-size: 1.25rem; /* Taille de police intermédiaire */
  }

  .hero .btn {
    font-size: 1.1rem; /* Taille de police intermédiaire */
    padding: 9px 18px; /* Padding intermédiaire */
  }
}

/* Styles pour les grands écrans (ordinateurs) */
@media (min-width: 992px) {
  .hero h2 {
    font-size: 3rem; /* Taille de police pour les grands écrans */
  }

  .hero p {
    font-size: 1.5rem; /* Taille de police pour les grands écrans */
  }

  .hero .btn {
    font-size: 0.9rem; /* Taille de police pour les grands écrans */
    padding: 10px 20px; /* Padding pour les grands écrans */
  }
}

/* Animation pour le bouton "Orthodontie" (défile de la droite vers la gauche) */
.slide-in-right {
  animation: slideInRight 0.5s ease-in-out forwards;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animation pour le bouton "Stomatologie" (défile de la gauche vers la droite) */
.slide-in-left {
  animation: slideInLeft 0.5s ease-in-out forwards;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Styles pour les boutons animés */
.animated-button {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px; /* Espace entre les boutons */
}

.animated-button:hover {
  transform: scale(1.05); /* Effet de zoom au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre au survol */
}

/* Pour mobiles (ex: < 768px) */
@media (max-width: 768px) {
  .video-card {
    padding: 1rem; /* Réduire l'espacement */
  }
  .tips-grid {
    grid-template-columns: 1fr; /* 1 colonne */
  }
}