/* Style général */
.ado-section {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, #ff7eb3, #ff758c);
    color: #fff;
}

.ado-section h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.ado-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

/* Galerie d'images défilantes */
.ado {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 20px;
    scroll-behavior: smooth;
}

.ado::-webkit-scrollbar {
    height: 8px;
}

.ado::-webkit-scrollbar-thumb {
    background-color: #ff758c;
    border-radius: 10px;
}

.ado img {
    width: 300px;
    height: 400px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease-in-out;
}

.ado img:hover {
    transform: scale(1.1);
}

/* Responsive */
@media (max-width: 768px) {
    .ado-section h2 {
        font-size: 2rem;
    }

    .ado img {
        width: 250px;
        height: 350px;
    }
}

@media (max-width: 480px) {
    .ado-section p {
        font-size: 1rem;
    }

    .ado img {
        width: 200px;
        height: 300px;
    }
}