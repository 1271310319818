/* Fichier CSS (par exemple : styles.css) */
.gallery-container {
    width: 100%; /* Largeur du conteneur */
    overflow-x: auto; /* Activation du défilement horizontal */
    white-space: nowrap; /* Empêche les images de passer à la ligne */
    padding: 20px;
    background-color: #f9f9f9; /* Fond légèrement gris */
    border-radius: 16px; /* Coins arrondis */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Ombre plus prononcée */
    border: 1px solid #e0e0e0; /* Bordure subtile */
  }
  
  .gallery-container::-webkit-scrollbar {
    height: 10px; /* Hauteur de la barre de défilement */
  }
  
  .gallery-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Couleur de fond de la barre de défilement */
    border-radius: 8px;
  }
  
  .gallery-container::-webkit-scrollbar-thumb {
    background: #888; /* Couleur de la barre de défilement */
    border-radius: 8px;
    transition: background 0.3s ease; /* Animation fluide */
  }
  
  .gallery-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Couleur de la barre de défilement au survol */
  }
  
  .gallery-item {
    display: inline-block; /* Alignement des images en ligne */
    margin-right: 20px; /* Espacement entre les images */
    position: relative; /* Pour les effets de superposition */
    transition: transform 0.3s ease; /* Animation fluide */
  }
  
  .gallery-item:last-child {
    margin-right: 0; /* Pas de marge à droite pour la dernière image */
  }
  
  .gallery-item img {
    width: 250px; /* Largeur fixe pour les images */
    height: auto;
    border-radius: 12px; /* Coins arrondis pour les images */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombre légère pour les images */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation au survol */
  }
  
  .gallery-item:hover {
    transform: translateY(-10px); /* Légère élévation au survol */
  }
  
  .gallery-item img:hover {
    transform: scale(1.05); /* Zoom léger au survol */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
  }
  
  /* Effet de superposition au survol */
  .gallery-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Fond semi-transparent */
    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover::after {
    opacity: 1; /* Affiche le fond au survol */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .gallery-item img {
      width: 200px; /* Réduire la largeur des images sur les tablettes */
    }
  }
  
  @media (max-width: 480px) {
    .gallery-item img {
      width: 150px; /* Réduire la largeur des images sur les mobiles */
    }
  }