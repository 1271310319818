/* Style général de la page */
.video-page-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4f0fb 100%);
  padding: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Bouton retour */
.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100;
  padding: 10px 15px;
  background: #0d6efd;
  color: white;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.back-button:hover {
  background: #0b5ed7;
  transform: translateY(-2px);
}

/* Nouveau conteneur principal */
.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 3rem;
}

/* Conteneur vidéo moyen */
.medium-video-container {
  width: 100%;
  margin: 0 auto 2rem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0,0,0,0.1);
}

.medium-video {
  width: 100%;
  height: auto;
  display: block;
  background: #000;
  aspect-ratio: 16/9;
}

/* Section conseils */
.medical-advice-container {
  width: 100%;
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.medical-title {
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}

.icon-spacing {
  margin-right: 15px;
  color: #0d6efd;
}

/* Grille de conseils */
.medical-tips-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 2rem 0;
}

.medical-tip-card {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;
  border-left: 4px solid #0d6efd;
}

.medical-tip-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0,0,0,0.1);
  background: #e9f5ff;
}

.tip-icon {
  font-size: 1.5rem;
  color: #0d6efd;
  margin-right: 15px;
  margin-top: 3px;
}

.tip-text {
  color: #495057;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

/* Note médicale */
.medical-note {
  margin-top: 2rem;
  padding: 1rem;
  background: #fff8e1;
  border-radius: 8px;
  border-left: 4px solid #ffc107;
  font-size: 0.9rem;
  color: #856404;
}

/* Responsive */
@media (max-width: 768px) {
  .video-page-container {
    padding: 1rem;
  }
  
  .content-wrapper {
    padding-top: 4rem;
  }
  
  .medical-title {
    font-size: 1.5rem;
  }
  
  .medical-tips-grid {
    grid-template-columns: 1fr;
  }
}