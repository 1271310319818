

/* Galerie.css */
#Galerie {
    padding: 60px 0;
    /* Fond clair */
}

.carousel {
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.carousel-inner {
    border-radius: 15px;
}

.carousel-item {
    transition: transform 0.5s ease-in-out;
}

.carousel-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Conserver le ratio d'aspect */
    object-fit: cover; /* Assure que l'image couvre tout l'espace */
    border-radius: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.carousel-indicators button {
    background-color: #000;
    border: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.carousel-indicators .active {
    background-color: #ff0000; /* Couleur rouge pour l'indicateur actif */
}

/* Effet au survol pour les images */
.carousel-image:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .carousel {
        max-width: 90%;
    }

    .carousel-image {
        aspect-ratio: 4 / 3; /* Ratio plus adapté pour les petits écrans */
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 10%;
    }
}

@media (max-width: 576px) {
    .carousel-indicators button {
        width: 8px;
        height: 8px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        padding: 8px;
    }
}