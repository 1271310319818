.ABC {
  box-shadow: 0 4px 8px 0 #15033f;
  max-width: 300px;
  margin: auto;
}

.texte {
  text-align: center;
}

.doctors {
  margin-bottom: 20px;
}

.doctor-name {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

.cabinet-name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .doctor-name {
      font-size: 1.8rem; /* Réduit la taille de police sur les petits écrans */
  }

  .cabinet-name {
      font-size: 0.8rem; /* Réduit la taille de la description sur les petits écrans */
  }

  .carousel-container {
      max-width: 100%; /* Ajuste la largeur du carrousel pour les petits écrans */
  }
}

.scroll {
  animation: slideRight 1.5s ease-out forwards; /* Pas d'animation au départ */
  opacity: 0;
}

@keyframes slideRight {
  0% {
      transform: translateX(-100px);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

.abc {
  animation: bounce 2s ease-in-out infinite; /* Animation continue */
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0); /* Position normale */
  }
  50% {
      transform: translateY(-20px); /* L'icône monte */
  }
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 400px; /* Ajuste selon tes besoins */
  height: auto;
  overflow: hidden;
}

.carousel-slide {
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.carousel-slide img {
  width: 100%;
  display: block;
  border-radius: 10px;
}

.carousel-slide.active {
  opacity: 1;
  transform: translateX(0);
  position: relative;
}