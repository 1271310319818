
.text {
    font-family: 'Raleway', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.8;
    text-align: left;
   
  }

  .title{
    font-size: 1.5rem;
  }

  .eto {
    color:#008080;
  }

  .techno{
    background-color: #008080;
  }

  .image-container:hover .zoom-icon {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
/* Styles pour le fond transparent */
.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important; /* Fond semi-transparent */
}

/* Styles pour le contenu de la modal */
.custom-modal-content {
  background-color: transparent !important; /* Fond transparent pour la modal */
  border: none !important; /* Supprime la bordure */
  box-shadow: none !important; /* Supprime l'ombre */
}

.sary {
  transition: transform 0.3s ease-in-out; /* Animation fluide */
}

.sary:hover {
  transform: scale(1.05); /* Zoom de 5% */
 
}
.hover-text {
  color: red;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
  text-align: center;
}

.hover-text:hover {
  color: black;
}

.hover-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background-color: red;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.hover-text:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}