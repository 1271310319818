/* Style pour la section */
.otho {
  width: 100%;
  height: 70vh; /* Hauteur par défaut pour les grands écrans */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(2, 2, 2, 0.5)),
              url('../Elements/image/ortho-7.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px 0;
}

/* Conteneur pour les images en triangle */
.image-triangle-container {
  position: relative;
  width: 100%;
  height: 400px; /* Hauteur pour les grands écrans */
}

/* Style pour chaque image */
.image-wrapper {
  position: absolute;
  width: 160px; /* Largeur des images */
  height: 160px; /* Hauteur des images */
}

/* Positionnement initial des images */
.image-1 {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.image-2 {
  bottom: 0;
  left: 10%; /* Ajustez cette valeur pour décaler à gauche */
}

.image-3 {
  bottom: 0;
  right: 10%; /* Ajustez cette valeur pour décaler à droite */
}

/* Style pour les images */
.image-wrapper img {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation au survol */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: rotateIn 1s ease-in-out; /* Animation d'apparition */
}

.image-wrapper img:hover {
  transform: scale(1.1); /* Effet de zoom au survol */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Ombre plus prononcée */
}

/* Animation pour les images */
@keyframes rotateIn {
  from {
      opacity: 0;
      transform: rotate(-90deg) scale(0.5);
  }
  to {
      opacity: 1;
      transform: rotate(0deg) scale(1);
  }
}

/* Style pour le titre */
.otho h2 {
  color: #ffffff;
  font-weight: bold;
  font-size: 3rem; /* Taille par défaut pour les grands écrans */
  animation: fadeIn 1s ease-in-out; /* Animation d'apparition */
}

/* Style pour la ligne horizontale */
.otho hr {
  border-top: 3px solid #fff; /* Épaisseur et couleur de la ligne */
  opacity: 1; /* Assure que la ligne est bien visible */
}

/* Style pour le texte */
.otho p {
  color: #ffffff;
  font-size: 1.25rem; /* Taille par défaut pour les grands écrans */
  animation: fadeIn 1s ease-in-out; /* Animation d'apparition */
}

/* Animation pour le texte */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .otho {
      height: auto; /* Hauteur automatique pour les petits écrans */
      padding: 60px 0; /* Espacement vertical */
  }

  .image-triangle-container {
      height: 300px; /* Réduire la hauteur pour les petits écrans */
  }

  .image-wrapper {
      width: 120px; /* Réduire la taille des images pour les petits écrans */
      height: 120px;
  }

  .image-1 {
      top: 20px; /* Ajuster la position pour les petits écrans */
  }

  .image-2 {
      bottom: 20px; /* Ajuster la position pour les petits écrans */
      left: 5%; /* Ajuster cette valeur pour décaler à gauche */
  }

  .image-3 {
      bottom: 20px; /* Ajuster la position pour les petits écrans */
      right: 5%; /* Ajuster cette valeur pour décaler à droite */
  }

  .otho h2 {
      font-size: 2rem; /* Taille réduite pour les petits écrans */
      margin-top: 20px; /* Ajuster la marge */
  }

  .otho p {
      font-size: 1rem; /* Taille réduite pour les petits écrans */
  }

  .otho hr {
      max-width: 200px; /* Réduire la largeur de la ligne */
  }
}

@media (max-width: 576px) {
  .image-triangle-container {
      height: 250px; /* Réduire encore la hauteur pour les très petits écrans */
  }

  .image-wrapper {
      width: 100px; /* Réduire encore la taille des images */
      height: 100px;
  }

  .image-1 {
      top: 10px; /* Ajuster la position pour les très petits écrans */
  }

  .image-2 {
      bottom: 10px; /* Ajuster la position pour les très petits écrans */
      left: 2%; /* Ajuster cette valeur pour décaler à gauche */
  }

  .image-3 {
      bottom: 10px; /* Ajuster la position pour les très petits écrans */
      right: 2%; /* Ajuster cette valeur pour décaler à droite */
  }
}