/* Style pour le loader */
.loader {
    text-align: center;
    color: #ff0000;
  }
  

  

  
  /* Style pour le formulaire */
  .form-container {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Style pour les champs du formulaire */
  .form-control, .form-select {
    border-radius: 10px;
    border: 1px solid #ced4da;
    padding: 0.75rem;
  }
  
  .form-control:focus, .form-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  /* Style pour le bouton de soumission */
  .btn-submit {
    background-color: #007bff;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    border-radius: 15px;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  /* Style pour les checkboxes */
  .form-check-input:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Style pour la section de remarque */
  .alert-info {
    background-color: #e9f5ff;
    border-color: #b8e2ff;
    color: #0c5460;
  }
  
  .alert-info ul {
    margin-bottom: 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .form-container {
      padding: 1rem;
    }
  
    .btn-submit {
      width: 100%;
    }
  }