.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.9); /* Fond semi-transparent */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Couleur de fond du loader */
    border-top: 8px solid #3498db; /* Couleur principale du loader */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    box-shadow: 0 0 20px rgba(52, 152, 219, 0.5); /* Ombre pour un effet 3D */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader-container p {
    margin-top: 20px;
    font-size: 1.2em;
    color: #3498db; /* Couleur du texte */
    font-weight: bold;
  }