.bg-teal {
  background-color: #fafafa;
}

.logo {
  height: 50px;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Pour que le navbar reste au-dessus des autres éléments */
  background-color: rgba(255, 255, 255, 0.7); /* Couleur avec transparence */
  backdrop-filter: blur(3px); /* Effet de flou */
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Ligne subtile en bas */
}

.navbar-brand, .navbar-nav .nav-link {
  color: rgb(46, 45, 45) !important;
}

.navbar-nav .nav-link:hover {
  color: #f01c1c !important; /* Jaune clair au survol */
}
.navbar.sticky::after {
  opacity: 1;
}
.navbar-nav .nav-link {
  color: #f8f8f8;
 
  padding: 10px 15px;
}

.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
  color: #4cbbbb;
  transition: color 0.3s ease; 
}

.dropdown-menu {
  background-color: #008080;
}

.dropdown-item {
  color:#008080;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #ffffff;
  color:#008080;
}

@media (max-width: 991px) {
  .navbar-nav {
    text-align: center;
  }
}