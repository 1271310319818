/* Structure principale */
.video-app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.video-page-container {
    position: relative;
    flex: 1;
    background: linear-gradient(135deg, #f8f9fa 0%, #e6f2ff 100%);
    overflow-x: hidden;
    padding-bottom: 2rem;
}

/* Motif de fond - plus léger sur mobile */
.background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
        radial-gradient(circle at 25% 25%, rgba(13, 110, 253, 0.04) 0%, transparent 15%),
        radial-gradient(circle at 75% 75%, rgba(13, 110, 253, 0.04) 0%, transparent 15%);
    background-size: 100px 100px;
    z-index: 0;
}

/* Contenu principal */
.video-main-content {
    position: relative;
    z-index: 1;
    padding: 2rem 5%;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
}

/* Bouton de retour optimisé mobile */
.scroll-top-btn {
    position: fixed;
    bottom: 80px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(135deg, #0d6efd, #0b5ed7);
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(13, 110, 253, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    z-index: 1000;
    animation: float 3s ease-in-out infinite;
}

.scroll-top-btn:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 6px 20px rgba(13, 110, 253, 0.4);
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

/* ============ IMPORTANT : Responsive Mobile ============ */
@media (max-width: 768px) {
    .video-page-container {
        padding-bottom: 1rem;
    }
    
    .video-main-content {
        padding: 1.5rem 3%;
    }
    
    .background-pattern {
        background-size: 80px 80px;
        opacity: 0.8;
    }
    
    .scroll-top-btn {
        width: 44px;
        height: 44px;
        bottom: 70px;
        right: 15px;
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .video-main-content {
        padding: 1rem 2%;
    }
    
    .scroll-top-btn {
        width: 40px;
        height: 40px;
        bottom: 65px;
        right: 10px;
        font-size: 1rem;
    }
    
    /* Optimisation pour très petits écrans */
    @media (max-height: 600px) {
        .scroll-top-btn {
            bottom: 60px;
        }
    }
}