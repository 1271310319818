.Image-enfants {
    height: 430px; /* Ajustez la hauteur selon vos besoins */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px; /* Coins arrondis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre */
    overflow: hidden;
}

.Image-enfants img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Pour s'assurer que l'image couvre toute la div */
}