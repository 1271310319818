/* styleEnquette.css */

/* Custom styles for the form */
.formulaire {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
  }
  
  .tete {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 120px; /* Ajustement pour la navbar fixe */
    border-bottom: 2px solid #dc3545; /* Ajout de l'épaisseur, du style et de la couleur */
  }
  
  .tete h1 {
    font-size: 2rem;
    color: #2c3e50;
    font-weight: bold;
  }
  
  .tete p {
    font-size: 1.25rem;
    color: #34495e;
  }
  
  .titre2 {
    font-size: 2rem;
    color: #dc3545; /* Rouge */
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .label {
    font-weight: 600;
    color: #34495e;
  }
  
  .form-legend {
    font-size: 1.25rem;
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .custom-textarea {
    width: 100%; /* Largeur à 100% du conteneur */
    max-width: 100%; /* Largeur maximale */
    resize: vertical; /* Permet à l'utilisateur de redimensionner verticalement */
  }
  
  .form-check-label {
    font-weight: normal;
    color: #34495e;
  }
  
  .but {
    background-color: #3498db;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .but:hover {
    background-color: #2980b9;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .formulaire {
      padding: 1rem;
    }
  
    .titre2 {
      font-size: 1.75rem;
    }
  
    .form-check-label {
      font-size: 0.9rem;
    }
  
    .tete h1 {
      font-size: 1.75rem;
    }
  
    .tete p {
      font-size: 1rem;
    }
  }